import { FC } from 'react';
import clsx from 'clsx';

import { Icon, IconButton, Search } from '@components';

import classes from './HeaderSearchDesktop.module.scss';

interface IProps {
  searchOpen: boolean;
  openSearch: () => void;
  closeSearch: () => void;
  isScrolled?: boolean;
  className?: string;
}

const HeaderSearchDesktop: FC<IProps> = ({ searchOpen, openSearch, closeSearch, isScrolled, className }) => (
  <div
    className={clsx(classes.searchWrapper, {
      [classes.wrapper]: isScrolled,
      [classes.fullWidth]: searchOpen,
      [className || '']: !!className
    })}
  >
    {searchOpen ? (
      <div className={classes.searchContainer}>
        <Search onClose={closeSearch} isScrolled={isScrolled} />
      </div>
    ) : (
      <IconButton className={classes.searchIcon} onClick={openSearch} ariaLabel="Пошук">
        <Icon type="search" size={24} offset={!isScrolled ? 8 : 0} />
        {!isScrolled ? <span className={classes.label}>Пошук</span> : null}
      </IconButton>
    )}
  </div>
);

export { HeaderSearchDesktop };
