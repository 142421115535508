import { WORKING_HOURS } from '@lib/miscellaneous';

import classes from './WorkingHours.module.scss';

export const WorkingHours = () => (
  <p className={classes.schedule}>
    {WORKING_HOURS}
    <br />
    без вихідних
  </p>
);
