import { WorkingHours } from '../WorkingHours/WorkingHours';

import classes from './PhonesCard.module.scss';

const phone = '+380 63 999 99 11';

export const PhonesCard = () => (
  <section className={classes.container}>
    <a className={classes.phoneNumber} href={`tel:${phone}`}>
      {phone}
    </a>
    <WorkingHours />
  </section>
);
