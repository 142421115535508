import { FC, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

import { useMainMenu } from '@contexts/main-menu';
import AccountLinks from '@layout/header/account-links/AccountLinks';
import { IMainMenuData } from '@types';
import { getRelAttribute } from '@utils/formatting';
import { HeaderSearchDesktop } from '../search/HeaderSearchDesktop';

import classes from './HeaderNavigationMenu.module.scss';

interface IMenuProps {
  items: IMainMenuData[];
  isScrolled?: boolean;
}

interface IMainMenuProps {
  item: IMainMenuData;
  index: number;
  activeIndex: number | null;
  handleMouseEnter: (index: number) => void;
  handleMouseLeave: () => void;
  isScrolled?: boolean;
}

interface IHeaderNavigationMenuProps {
  isScrolled?: boolean;
  className?: string;
}

function getRowQty(itemsQty: number) {
  if (itemsQty <= 12) return 4;
  if (itemsQty <= 24) return 6;
  return itemsQty / 4;
}

const MenuItem: FC<IMainMenuProps> = ({ handleMouseEnter, handleMouseLeave, index, item, activeIndex, isScrolled }) => {
  const itemRef = useRef<HTMLAnchorElement>(null);

  if (!item.children || !item.children.length) {
    return (
      <li>
        <Link
          className={clsx(classes.categoryTitleLink, {
            [classes.specialCategoryTitleLink]: item.special,
            [classes.titleLink]: isScrolled
          })}
          href={item.seoKey}
        >
          {item.name}
        </Link>
      </li>
    );
  }

  const leftPosition = (itemRef.current?.getBoundingClientRect().width || 2) / 2;
  const rowsQty = getRowQty(item.children.length);
  const columnsQty = Math.ceil(item.children.length / rowsQty);

  return (
    <li onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} style={{ position: 'relative' }}>
      <Link
        className={clsx(classes.categoryTitleLink, {
          [classes.specialCategoryTitleLink]: item.special,
          [classes.titleLink]: isScrolled
        })}
        href={item.seoKey}
        ref={itemRef}
      >
        {item.name}
      </Link>
      <ul
        style={{
          opacity: activeIndex === index ? 1 : 0,
          transform: activeIndex === index ? 'translateY(0)' : 'translateY(-20px)',
          visibility: activeIndex === index ? 'visible' : 'hidden',
          pointerEvents: activeIndex === index ? 'auto' : 'none',
          gridTemplateColumns: `repeat(${columnsQty}, 1fr)`,
          gridTemplateRows: `repeat(${rowsQty - 1}, 1fr)`
        }}
        className={classes.popupMenuContainer}
      >
        <div className={classes.popupMenuArrow} style={{ left: leftPosition }}>
          <svg viewBox="0 0 32 16" style={{ position: 'absolute' }}>
            <path d="M16 0l16 16H0z" fill="white"></path>
          </svg>
        </div>
        {item.children.map((child) => (
          <Link
            href={child.seoKey}
            key={child.seoKey}
            className={classes.categorySubTitleLink}
            {...getRelAttribute(child.seoKey)}
          >
            <div className={classes.itemContainer}>
              <Image
                width={40}
                height={40}
                className={classes.itemImage}
                src={child.image || 'assets/images/flower-no-image.svg'}
                alt={child.name}
              />
              <p className={classes.children}>{child.name}</p>
            </div>
          </Link>
        ))}
      </ul>
    </li>
  );
};

const Menu: FC<IMenuProps> = ({ items, isScrolled }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <nav className={classes.nav}>
      <ul className={classes.navigationList}>
        {items
          ? items.map((item, idx) => (
              <MenuItem
                key={idx}
                index={idx}
                item={item}
                activeIndex={activeIndex}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                isScrolled={isScrolled}
              />
            ))
          : null}
        <li className={clsx(classes.offersItem, { [classes.titleLink]: isScrolled })}>
          <Link href="/sales">Акції</Link>
        </li>
      </ul>
    </nav>
  );
};

const HeaderNavigationMenu: FC<IHeaderNavigationMenuProps> = ({ isScrolled, className }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const { mainMenu } = useMainMenu();

  function closeSearch() {
    setSearchOpen(false);
  }

  function openSearch() {
    setSearchOpen(true);
  }

  // close search on scroll to top
  useEffect(() => {
    if (!isScrolled) {
      setSearchOpen(false);
    }
  }, [isScrolled]);

  return (
    <div className={clsx(classes.navigationMenuContainer, className, { [classes.menuScrolled]: isScrolled })}>
      {!searchOpen ? <Menu items={mainMenu} isScrolled={isScrolled} /> : null}
      {isScrolled ? (
        <HeaderSearchDesktop
          searchOpen={searchOpen}
          closeSearch={closeSearch}
          openSearch={openSearch}
          isScrolled={isScrolled}
        />
      ) : null}
      {isScrolled && !searchOpen ? <AccountLinks withoutUser={isScrolled} /> : null}
    </div>
  );
};

export { HeaderNavigationMenu };
