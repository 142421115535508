import Popup from 'reactjs-popup';
import Link from 'next/link';

import { Icon } from '@components';

import classes from './WebsiteLinks.module.scss';

const WebsiteLinks = () => {
  return (
    <Popup
      on={['click']}
      closeOnDocumentClick
      trigger={() => (
        <div className={classes.clientAndBlog}>
          Клієнтам
          <Icon type="arrowExpand" leftOffset={4} width={10} className={classes.arrowExpand} />
        </div>
      )}
      contentStyle={{
        width: 250,
        border: '1px solid #EBE4E4',
        padding: '12px 20px',
        backgroundColor: '#fff',
        borderRadius: 12
      }}
      arrowStyle={{
        display: 'none'
      }}
      position="bottom left"
    >
      <div className={classes.links}>
        <Link href="/delivery" className={classes.link}>
          Доставка
        </Link>
        <Link href="/payment" className={classes.link}>
          Оплата
        </Link>
        <Link href="/contacts" className={classes.link}>
          Контакти
        </Link>
        <Link href="/shops" className={classes.link}>
          Магазини
        </Link>
        <Link href="/about-us" className={classes.link}>
          Про нас
        </Link>
        <Link href="/blog" className={classes.link}>
          Блог
        </Link>
        <Link href="/replacement" className={classes.link}>
          Заміна та повернення
        </Link>
      </div>
    </Popup>
  );
};

export default WebsiteLinks;
