'use client';

import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { useIsMobile } from '@contexts/mobile-detector-provider';
import { HeaderDesktop } from './header-desktop/HeaderDesktop';
import { HeaderMobile } from './header-mobile/HeaderMobile';

import classes from './HeaderMain.module.scss';

const Header = () => {
  const isMobile = useIsMobile();
  const [isScrolled, setIsScrolled] = useState(false);
  const headerTopRef = useRef(null);

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    const headerTopEl = headerTopRef.current;
    let observer: IntersectionObserver;
    if (headerTopEl) {
      observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          const isCurrentlyIntersecting = !entry.isIntersecting;
          if (isScrolled !== isCurrentlyIntersecting) {
            setIsScrolled(isCurrentlyIntersecting);
          }
        },
        { threshold: 0 }
      );

      observer.observe(headerTopEl);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (headerTopEl) {
        observer.unobserve(headerTopEl);
      }
    };
  }, []);

  return (
    <header className={clsx(classes.header, { [classes.stickyHeader]: isScrolled })}>
      {isMobile ? <HeaderMobile /> : <HeaderDesktop headerTopRef={headerTopRef} isScrolled={isScrolled} />}
    </header>
  );
};

export default Header;
