import { MainLogoLink } from '@components';
import { SocialLinks } from '@components/shared';

import classes from './FooterSocialLinks.module.scss';

interface IProps {
  logoHeight?: number;
  logoWidth?: number;
}

const FooterSocialLinks = ({ logoHeight, logoWidth }: IProps) => (
  <div className={classes.wrapper}>
    <MainLogoLink height={logoHeight || 36} width={logoWidth || 200} classNameWrapper={classes.footerSocialLinks} />
    <SocialLinks />
  </div>
);

export { FooterSocialLinks };
