export interface IStaticPageProps {
  title: string;
  url: string;
}

export const staticPages: Record<string, IStaticPageProps> = {
  aboutUs: {
    title: 'Про нас',
    url: '/about-us'
  },
  contacts: {
    title: 'Контакти',
    url: '/contacts'
  },
  blog: {
    title: 'Блог',
    url: '/blog'
  },
  shops: {
    title: 'Магазини',
    url: '/shops'
  },
  publicOferta: {
    title: 'Публічна оферта',
    url: '/public-oferta'
  },
  replacement: {
    title: 'Заміна та повернення',
    url: '/replacement'
  },
  delivery: {
    title: 'Доставка',
    url: '/delivery'
  },
  payment: {
    title: 'Оплата',
    url: '/payment'
  }
};
